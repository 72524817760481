<template>
    <section class="">
        <div class="active-chat w-100">
            <b-row class="mb-5">
                <b-col cols="12">
                    <b-button variant="primary" type="button" :to="{name: 'user-ai-images'}">
                        {{ $t('Generate Image') }}
                    </b-button>
                </b-col>
            </b-row>
            
            <b-row class="mb-5">
                <b-col cols="12">
                    <b-form class="chat-app-form" @submit.prevent="searchSavedImage">
                        <b-row>
                            <b-col cols="12" md="8">
                                <b-input-group class="input-group-merge form-send-message mr-1 mb-2">
                                    <b-form-input :disabled="loading" v-model="query" name="find_image" placeholder="Search image" />
                                </b-input-group>
                            </b-col>
                            <b-col cols="12" md="4">
                                <b-button variant="primary" type="submit" class="mr-1" :disabled="isEmpty(query) || loading">
                                    {{ $t('Search') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-col>
            </b-row>

            <div class="row">
                <div class="col-12">
                    <div class="row mb-5" id="infinite_list">
                        <div class="col-12 col-md-3 mb-1" v-for="(image, ik) in savedImages" :key="`saved_images_${ik}`">
                            <GeneratedImage :aiImageTree="image" />
                        </div>
                    </div>
                </div>
            </div>
            <b-row class="mb-5">
                <b-col cols="12" class="d-flex justify-content-center">
                    <template v-if="last_page == currentPage">
                        <b-button v-if="loadingMore" variant="secondary" disabled >{{ $t('Loading') }}...</b-button>
                        <small v-else class="text-muted text-uppercase">{{ $t('End of the results') }}</small>
                    </template>
                    <template v-else>
                        <b-button variant="primary" v-if="!loadingMore" @click="nextPage">{{ $t('Load More') }}</b-button>
                        <b-button variant="secondary" disabled v-else>{{ $t('Loading') }}...</b-button>
                    </template>
                </b-col>
            </b-row>
            <div class="h-10vh d-block"></div>
        </div>
    </section>
</template>
  
<script>
import { mapActions } from 'vuex'
import { isEmpty } from '@/utilities'
import GeneratedImage from '@/views/users/ai/components/GeneratedImage.vue'
import { hideLoader, showLoader } from '@/@core/comp-functions/ui/app'
export default {
    name: 'AISavedImages',

    components: {
        GeneratedImage
    },

    props: {

    },

    data: () => ({
        loading: false,
        aiImages: [],
        savedImages: [],
        currentPage: 1,
        query: null,
        old_query: null,
        last_page: 1,
        loadingMore: false
    }),

    mounted() {
        this._getSavedImages()
    },
    methods: {
        isEmpty,
        ...mapActions('ai', [
            'getSavedImages',
        ]),
        async nextPage(){
            if(this.last_page > this.currentPage && !this.loadingMore){
                this.currentPage += 1
                this.loadingMore = true
                await this.getSavedImages({
                    query: this.query,
                    page: this.currentPage,
                }).then(r => {
                    if(!isEmpty(r.responseData)){
                        const paginatedData = r.responseData
                        const {data} = paginatedData
                        this.savedImages = this.savedImages.concat(data)
                    }
                })
                this.loadingMore = false
            }
        },
        async searchSavedImage(){
            const oldQuery = typeof this.old_query == 'string' ? this.old_query.trim() : null;
            const query = typeof this.query == 'string' ? this.query.trim() : null;
            if(query != oldQuery){
                this.currentPage = 1
                this.old_query = this.query
            }
            this.savedImages = []
            this._getSavedImages()
        },
        async _getSavedImages(){
            showLoader()
            this.loading = true
            await this.getSavedImages({
                query: this.query,
                page: this.currentPage,
            }).then(data => {
                if(!isEmpty(data.responseData)){
                    const paginatedData = data.responseData
                    const {data: images, last_page} = paginatedData
                    this.last_page = last_page
                    this.savedImages = images
                }
            })
            this.loading = false
            hideLoader()
        },
    },
}
</script>
